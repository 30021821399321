<template>
    <section class="content">
        <div class="card">
            <div class="card-header">
                Filter Pencarian
            </div>
                <!-- <div class="p-5"></div> -->
            <div class="card-body row">
                <div class="col-md-6 form-group">
                    <label class="control-label">Tanggal</label>
                    <datepicker
                      placeholder="Tanggal"
                      class="datep"
                      v-model="form.f_tanggal"
                      :options="{format:'yyyy-mm-dd', autoclose: true}" 
                      :autoclose=true
                      required
                    />
                </div>
                <div class="col-md-6 form-group">
                    <label class="control-label">Kelas</label>
                    <v-select :options="optKelas" v-model="form.kelas_id" :reduce="opt => opt.id" />
                </div>
            </div>
            <div class="card-footer">
                <button type="submit" v-on:click="search" class="btn btn-primary btn-md float-right">Cari</button>
            </div>
        </div>

        <form role="form" @submit.prevent="submitForm">
        <div class="card">
            <div class="card-header">
            </div>
            <div class="card-body">
                <table class="table table-hover" ref="tableuser">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>NAMA SISWA</th>
                            <th>PUSH UP</th>
                            <th>SIT UP</th>
                            <th>PULL UP</th>
                            <th>SHUTTLE RUN</th>
                            <th>LARI</th>
                            <th>RENANG</th>
                            <th>ID SISWA</th>
                            <th>PUSH TYPE</th>
                            <th>TINDAKAN</th>
                        </tr>
                    </thead>
                    <tbody @click="handleClick"></tbody>
                </table>
            </div>
            <div class="card-footer">
            <button type="submit" class="btn btn-primary mr-1">Simpan</button>
            </div>
        </div>
        </form>
    </section>
</template>
<script>
import { createTable, authFetch } from '@/libs/hxcore';
import $ from 'jquery';
import datepicker from '@/components/Datepicker';
import vSelect from 'vue-select';
import "vue-select/dist/vue-select.css";
import moment from "moment";
import Swal from "sweetalert2";

export default {
    name: 'FormPenilaianJasmani',
    components: {
        vSelect,
        datepicker,
    },
    data() {
        return {
            errors: [],
            optKelas: [],
            roles: '',
            form: {
                f_tanggal: '',
                kelas_id: '',
            },
            formItem: {
				id: '',
				nama_siswa: '',
                count_pushup: '',
                count_situp: '',
                count_pullup: '',
                count_shuttlerun: '',
                count_run: '',
                count_swim: '',
            },
            pushtype: '',
            tgl: '',
            kelas: '',
        }
    },
    created: function() {
        this.form.f_tanggal = moment().format("YYYY-MM-DD");
        // this.roles = this.$route.meta.roles;
        // console.log('load initial data', this.$route)

        authFetch('/akademik/jadwal_tentor/kelas')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.optKelas = js.data;
            });
    },
    methods: {
        search: function() {
            let self = this;
            self.tgl = self.form.f_tanggal;
            self.kelas = self.form.kelas_id;
						// var nRow = this.table.fnAddRow(this.formItem);
            // var aData = {
            //   nama_siswa: this.formItem.nama_siswa,
            //   count_pushup: this.formItem.count_pushup,
            //   count_situp: this.formItem.count_situp,
            //   count_pullup: this.formItem.count_pullup,
            //   count_shuttlerun: this.formItem.count_shuttlerun,
            //   count_run: this.formItem.count_run,
            // };
            // this.table.fnUpdate(aData, nRow);
            // $('td .btn.edit', nRow).click();

						// var nRow = this.table.fnAddRow('<div class="btn-group"><a class="btn edit btn-xs icon-edit btn-primary"><i class="fas fa-pen"></i> Edit</a><a class="btn delete btn-xs icon-remove btn-danger"><i class="fa fa-trash"></i> Delete</a></div>');
            // $('td .btn.edit', nRow).click();

            this.table.api().ajax.reload();
        },
        getFormItem()
        {
            var aData = {
                id: this.formItem.id,
                nama_siswa: this.formItem.nama_siswa,
                count_pushup: this.formItem.count_pushup,
                count_situp: this.formItem.count_situp,
                count_pullup: this.formItem.count_pullup,
                count_shuttlerun: this.formItem.count_shuttlerun,
                count_run: this.formItem.count_run,
                count_swim: this.formItem.count_swim,
            };
            return aData;
        },
        submitForm: function(ev) {
            const self = this;

            var items = [];
            this.table
                .api()
                .rows()
                .every(function(rowIdx, tableLoop, rowLoop) {
                    var d = this.data();
                    items.push(d);
                });
            var allData = { items: items };
            Swal.fire({
            title: "Simpan?",
            showCancelButton: true,
            confirmButtonText: `Ya`,
            cancelButtonText: "Tidak",
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                authFetch("/administrasi/penilaian_jasmani/update", {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(allData),
                })
                    .then((res) => {
                    return res.json();
                    })
                    .then((js) => {
                    if (js.success) {
                        Swal.fire("Proses Berhasil", ``, "success");
                        this.$router.push("/penilaian-jasmani");
                    } else {
                        Swal.fire("Proses gagal", ``, "error");
                        // this.table.api().ajax.reload();
                    }
                    });
                }
            });

            ev.preventDefault();
        },
        handleClick(e) {},
    },
    mounted() {
        console.log('load initial data', this.$route.params.roles);
        const e = this.$refs;
        var self = this;

        this.table = createTable(e.tableuser, {
            title: "",
            roles: ["read"],
			frame: true,
            ajax: "/administrasi/penilaian_jasmani",
			language: {
				zeroRecords: "Tidak ada siswa yang absensi!"
			},
			serverSide: false,
            selectedRowClass: "",
            columns: [
                { data: "id",
                sortable: false,
                visible: false, },
                { data: "nama_siswa" },
                { data: "count_pushup"},
                { data: "count_situp"},
                { data: "count_pullup"},
                { data: "count_shuttlerun"},
                { data: "count_run"},
                { data: "count_swim"},
                { data: "siswa_id",
                sortable: false,
                visible: false, },
                { data: "push_type",
                visible: false,
                render: function (data, row, type, meta) {
                    self.pushtype = data;
                    return self.pushtype;
                },},
				{ data: "action",
                sortable: false,
                render: function (data, row, type, meta) {
                  return self.pushtype == 4 ? '<h5 style="display:inline-block;"><span class="badge badge-danger">ALPHA</span></h5>' : '<div class="btn-group"><a class="btn edit btn-xs icon-edit btn-primary"><i class="fas fa-pen"></i> Edit</a></div>';
                },},
            ],
            filterBy: [1, 2],
            rowCallback: function(row, data) {
            // $("td:eq(1)", row).attr('contentEditable', false);
            },
            paramData: function ( d ) {
                d.tgl = self.tgl;
                d.kelas_id = self.kelas;
            },
        }).fnEditor({
            action: self.pushtype == 4 ? '<h5 style="display:inline-block;"><span class="badge badge-danger">ALPHA</span></h5>' : '<div class="btn-group"><a class="btn edit btn-xs icon-edit btn-primary"><i class="fas fa-pen"></i> Edit</a></div>'
        });
    }
}
</script>